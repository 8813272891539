<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu trả hàng'">
      <template v-slot:toolbar>
        <div class="row">
          <div>
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="showModalExcel">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
              <b-dropdown-item @click="showModalExportExcel" v-if="false">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel theo cửa hàng</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <router-link
            to="/returnBills/add-return-bill-with-no-bill"
            v-if="false"
          >
            <button
              type="button"
              class="btn btn-primary font-weight-bolder btn-sm"
            >
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>Tạo mới
            </button>
          </router-link>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              required
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
              v-on:change="onChangeCompany"
              class="select-style"
            >
              <template v-slot:first>
                <b-form-select-option :value="null" disabled
                  >-- Doanh nghiệp --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filteredStore"
              :placeholder="'cửa hàng'"
              :matchKey="['name']"
              :searchable="true"
              :limit="5"
            />
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập tên sản phẩm"
              v-model="searchProductName"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <div class="search-col">
              <b-input-group>
                <date-picker
                  placeholder="Từ ngày"
                  class="form-control form-control-sm customer-input"
                  :config="dpConfigs.date"
                  v-model="dateFrom"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Tới ngày"
                  class="form-control form-control-sm customer-input"
                  :config="dpConfigs.date"
                  v-model="dateTo"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập ID hóa đơn bán"
              v-model="searchOrderId"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              placeholder="Nhập ID"
              v-model="searchId"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập tên KH/Sdt"
              v-model="searchNameOrPhone"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập ghi chú"
              v-model="searchNote"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col>
            <b-input
              placeholder="Nhập mã IMEI"
              v-model="searchIMEI"
              append-icon="search"
              single-line
              hide-details
              class="btn-sm customer-input"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col> </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <table class="table b-table table-bordered-list table-hover col-md-12">
          <thead>
            <tr>
              <th class="text-center tdDate">Ngày</th>
              <th class="text-center tdCode">ID</th>
              <th class="text-center tdStore">Cửa hàng</th>
              <th class="text-center tdCustomer">Khách hàng</th>
              <th class="text-center tdProductName">Sản phẩm</th>
              <th class="text-center tdProductPrice">Giá</th>
              <th class="text-center tdQuantity">SL</th>
              <th class="text-center tdDiscountItem">C.Khấu</th>
              <th class="text-center tdTotalPayment">Trả lại</th>
              <th class="text-center tdReturnFee">Phí trả hàng</th>
              <th class="text-center tdTotalAmount">Tổng tiền</th>
              <th class="text-center tdNote">Ghi chú</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="entry in bills"
              :key="entry.id"
              @mouseover="hoverBill"
              @mouseleave="outBill"
              :data="`row_${entry.billNumber}`"
            >
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                {{ entry.date }}
                <br />
                <p style="margin-bottom: 0px" v-if="entry.cashierName">
                  {{ entry.cashierName }}
                </p>
                <p
                  v-if="entry.saleName"
                  style="margin-bottom: 0px; font-weight: 600"
                >
                  NVBH:
                </p>
                {{ entry.saleName }}
              </td>
              <td class="tdCenter" v-if="entry.show" :rowspan="entry.groupSize">
                <div
                  class="orderCode"
                  @click="updateItem(entry)"
                  style="cursor: pointer; color: #3699ff"
                >
                  <a
                    class="font-weight-bold"
                    v-bind:href="getLinkDoc(entry)"
                    target="_blank"
                  >
                    {{ entry.billNumber }}
                  </a>
                </div>
                <p>Trả hàng từ hóa đơn</p>
                <div
                  class="orderCode"
                  @click="editItem(entry)"
                  style="cursor: pointer; color: #3699ff"
                >
                  <span
                    v-text="entry.returnFromBillNumber"
                    class="text-primary"
                  ></span>
                </div>
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                {{ entry.storeName }}
                <br />
                <span v-if="entry.companyName"
                  >( {{ entry.companyName }} )</span
                >
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0; cursor: pointer; font-weight: 550"
                >
                  {{ entry.customerName }}
                </p>
                <p
                  class="orderCode"
                  @click="editUser(entry)"
                  style="margin-bottom: 0; cursor: pointer"
                >
                  {{ entry.customerMobile }}
                </p>
              </td>
              <td class="tdTextAlign">
                <div>
                  <i
                    style="color: rgb(24, 28, 50)"
                    class="fas fa-gift ml-4"
                    v-if="entry.productItem.productType === 2"
                  ></i>
                  <i
                    style="color: #2e7d32"
                    class="fas fa-tags ml-4"
                    v-else-if="entry.productItem.productType === 3"
                  ></i>
                  {{ entry.productItem.productName }}
                </div>
                <p
                  class="text-imei-no"
                  v-if="entry.productItem.imeiCode.trim() !== ''"
                >
                  IMEI: {{ entry.productItem.imeiCode }}
                </p>
                <MerchantLabel :merchantId="entry.productItem.merchantId" />
              </td>
              <td class="tdTextAlignPrice">
                {{ convertPrice(entry.productItem.productPrice) }}
              </td>
              <td class="tdTextAlignPrice">
                {{ entry.productItem.quantity }}
              </td>
              <td class="tdTextAlignPrice">
                {{ convertPrice(entry.productItem.discountItemAmount) }}
              </td>
              <td
                class="tdTextAlignPrice"
                v-if="entry.show"
                :rowspan="entry.groupSize"
              >
                {{ convertPrice(entry.paymentAmount) }}
              </td>
              <td
                class="tdTextAlignPrice"
                v-if="entry.show"
                :rowspan="entry.groupSize"
              >
                {{ convertPrice(entry.returnFee) }}
              </td>
              <td
                class="tdTextAlignPrice"
                v-if="entry.show"
                :rowspan="entry.groupSize"
                style="color: #ffa800"
              >
                {{ convertPrice(entry.totalAmount) }}
              </td>
              <td
                class="tdTextAlign"
                v-if="entry.show"
                :rowspan="entry.groupSize"
                @mouseover="hoverPrice = true"
                @mouseleave="hoverPrice = false"
              >
                {{ entry.note }}
                <span @click="handleShowNoteModel(entry)">
                  <i v-if="hoverPrice" class="fas fa-edit"></i>
                </span>
              </td>
              <td
                v-if="entry.show"
                :rowspan="entry.groupSize"
                class="tdTextAlign"
              >
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item @click="printBill(entry)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem"
                          class="flaticon2-printer"
                        ></i>
                        &nbsp; In hóa đơn
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="updateItem(entry)">
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(entry)"
                      v-if="checkPermission('BILL_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td :colspan="4" class="tdTextAlignPrice text-weight">Tổng</td>
              <td></td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ totalQuantity }}
              </td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalPayment) }}
              </td>
              <td></td>
              <td class="tdTextAlignPrice text-weight">
                {{ convertPrice(totalAmount) }}
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <vcl-table
          v-if="onLoading"
          :speed="5"
          :animate="true"
          :columns="11"
        ></vcl-table>
        <!-- End of Table session -->

        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="'Sửa ghi chú cho hóa đơn ' + idBill"
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú</label
                >
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số hóa đơn:
              {{ convertPrice(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-show="totalPages >= 2"
              class="custom-pagination"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
              @change="fetchListBill"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
    <template>
      <BillExcel :param="apiParams" :mode="mode" />
    </template>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import BillExcel from '../../components/bills/BillExcel.vue';
import MerchantLabel from '../../components/MerchantLabel.vue';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

import moment from 'moment';
import { cloneDeep, map, find, assign } from 'lodash';

export default {
  data() {
    return {
      listStores: [{ value: '', text: 'Chọn cửa hàng' }],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      hoverPrice: false,
      dateFrom: moment().subtract(15, 'days').format('DD/MM/YYYY'),
      dateTo: moment().format('DD/MM/YYYY'),
      bills: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      searchProductName: '',
      searchId: '',
      searchNameOrPhone: '',
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      idBill: '',
      showEditPriceModal: false,
      note: '',
      totalAmount: 0,
      totalPayment: 0,
      totalQuantity: 0,
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedStore: null,
      searchStock: '',
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      searchIMEI: '',
      searchOrderId: '',
      searchNote: '',
      apiParams: {
        fromDate: null,
        toDate: null,
      },
      mode: null,
      filteredStore: [],
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker,
    BillExcel,
    MerchantLabel,
    BDropdownCustom
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu trả hàng', route: 'returnBills' },
      { title: 'Danh sách phiếu trả hàng' },
    ]);
  },
  created() {
    let billNumber = this.$route.query.id;
    if (billNumber !== undefined) {
      this.searchId = billNumber;
    }
    this.fetchStore();
    this.fetchStoreByUser();
    this.fetchCompany();
  },
  methods: {
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`
      ).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
          this.optionsStore.push(option);
        });
        this.filteredStore = cloneDeep(this.optionsStore);
      });
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    editItem: function (item) {
      let nameRouter = '';
      if (item.returnFromBillType === 1) {
        nameRouter = 'update-bill';
      } else if (item.returnFromBillType === 2) {
        nameRouter = 'update-wholesale-invoice';
      }
      const { href } = this.$router.resolve({
        name: nameRouter,
        query: { id: item.returnFromBillNumber },
      });
      window.open(href, '_blank');
    },
    updateItem: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-return-bill',
        query: { id: item.billNumber },
      });
      window.open(href, '_blank');
    },
    handleShowNoteModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.idBill = item.billNumber;
      this.note = item.note;
    },
    updateNote() {
      let data = {
        id: this.idBill,
        customerNote: this.note,
      };
      ApiService.setHeader();
      ApiService.put('bills/update-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditPriceModal = !this.showEditPriceModal;
            this.fetchListBill();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    searchOrder() {
      this.isSearching = true;
      this.fetchListBill();
    },
    fetchListBill: async function () {
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }
      this.filteredStore = cloneDeep(this.optionsStore);
      this.totalQuantity = 0;
      this.totalAmount = 0;
      this.totalPayment = 0;
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const param = {
        page: this.page,
        limit: 10,
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: 3,
        companyId: this.selectedCompany,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('bills', paramQuery).then((response) => {
        const { dataset, totalPage, totalRow } = response.data.data;
        this.bills = [];
        this.totalPages = totalPage;
        this.totalItems = totalRow;
        dataset.forEach((data) => {
          for (let index = 0; index < data.listProduct.length; index++) {
            const element = data.listProduct[index];
            let show = false;
            if (index === 0) {
              show = true;
            }
            let discountAmount = this.convertPrice(data.discountAmount);
            if (data.discountType === 2) {
              discountAmount = data.discountAmount + '%';
            }
            let item = {
              id: element.id,
              billNumber: data.billNumber,
              customerName: data.customerName,
              customerPhone: data.customerMobile,
              customerId: data.customerId,
              saleName: data.saleName,
              cashierName: data.cashierName,
              storeName: data.storeName,
              totalAmount: data.totalAmount,
              paymentAmount: data.paymentAmount,
              returnFee: data.returnFee,
              discountAmount: discountAmount,
              note: data.customerNote,
              productItem: element,
              date: data.createdAt,
              show: show,
              groupSize: data.listProduct.length,
              returnFromBillNumber: data.returnFromBillNumber,
              returnFromBillType: data.returnFromBillType,
              productType: element.productType,
              companyName: data.companyName,
            };
            this.bills.push(item);
            this.totalQuantity += element.quantity;
            if (show) {
              this.totalAmount += data.totalAmount;
              this.totalPayment += data.paymentAmount;
            }
          }
        });
      });
      this.onLoading = false;
    },
    deleteItem: async function (item) {
      ApiService.delete('/bills/v2' + '/' + item.billNumber).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchListBill();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa hóa đơn!',
        text: 'Bạn có chắc muốn xóa hóa đơn này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    debounceInput: decounce(function () {
      this.searchOrder();
    }, 2000),
    onFilter() {
      const isValidParam = this.validateParamSearch();
      if(!isValidParam){
        return;
      }
      this.$route.query.page = 1;
      this.changeURL();
      this.fetchListBill();
    },
    validateParamSearch(){
      const textSearchProduct = this.searchProductName ? this.searchProductName.trim() : '';
      if(textSearchProduct && textSearchProduct.length < 10) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm sản phẩm trên 10 ký tự!')
        return false;
      }
      const textSearchIMEI = this.searchIMEI ? this.searchIMEI.trim() : '';
      if(textSearchIMEI && textSearchIMEI.length < 5) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm IMEI trên 5 ký tự!')
        return false;
      }
      const textSearchNote = this.searchNote ? this.searchNote.trim() : '';
      if(textSearchNote && textSearchNote.length < 5) {
        this.makeToastFaile('Vui lòng nhập tìm kiếm ghi chú trên 5 ký tự!')
        return false;
      }
      return true;
    },
    changeURL() {
      this.$router.push({
        name: 'list-return-bill',
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
    },
    hoverBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.addClass('tr-rowspan-hover');
    },
    outBill: function (evt) {
      const items = jQuery(evt.currentTarget);
      const dataId = jQuery(items[0]).attr('data');
      const rows = jQuery(`[data=${dataId}]`);
      rows.removeClass('tr-rowspan-hover');
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.searchStock = '';
      this.fetchStore();
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = true;
      this.filteredStore = cloneDeep(this.optionsStore);
    },
    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name
      );
      this.optionsStore[index].checked = false;
      this.filteredStore = cloneDeep(this.optionsStore);
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              let index = this.optionsStore.findIndex(
                (item) => item.code == element.id
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filteredStore = cloneDeep(this.optionsStore);
        }
        this.fetchListBill();
      });
    },
    getLinkDoc(item) {
      return `#/returnBills/update-return-bill?id=${item.billNumber}`;
    },
    editUser: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerMobile  },
      });
      window.open(href, '_blank');
    },
    printBill: async function (item) {
      let routeData = this.$router.resolve({
        name: 'print-bill',
        query: { id: item.billNumber },
      });
      window.open(routeData.href, '_blank');
    },
    showModalExcel() {
      this.mode = 3;
      this.apiParams = {
        fromDate: this.dateFrom,
        toDate: this.dateTo,
      };
      this.$bvModal.show('modal-bill-excel');
    },
    countBill: async function () {
      let result = 0;
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }

      this.filteredStore = cloneDeep(this.optionsStore);
      this.onLoading = true;
      let param = {
        id: this.searchId,
        customer: this.searchNameOrPhone,
        store: listStoreId,
        searchProduct: this.searchProductName,
        type: 3,
        fromDate: this.dateFrom
          ? moment(this.dateFrom, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        toDate: this.dateTo
          ? moment(this.dateTo, 'DD/MM/YYYY HH:mm').format('yyyy-MM-DD 23:59')
          : null,
        companyId: this.selectedCompany,
        orderId: this.searchOrderId ? this.searchOrderId.trim() : '',
        searchIMEI: this.searchIMEI ? this.searchIMEI.trim() : '',
        searchNote: this.searchNote ? this.searchNote.trim() : '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      await ApiService.query('bills/count', paramQuery).then(({ data }) => {
        result = data.data;
      });
      return result;
    },
    onInputed(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style>
.orderCode:hover {
  text-decoration: underline;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
.tdTextAlign {
  vertical-align: left;
}
.tdTextAlignPrice {
  text-align: right !important;
  vertical-align: middle;
}
.table-bordered-list th {
  border: 0.5px solid #d2d4d6 !important;
  background-color: #e0e0e0;
}
.table-bordered-list td {
  border: 0.5px solid #d2d4d6;
}
.text-weight {
  font-weight: 550;
}

.test {
  position: absolute;
  right: 1vw;
}
.checkbox-label {
  display: block;
}

.tdDate {
  width: 5%;
}
.tdCode {
  width: 5%;
}
.tdStore {
  width: 5%;
}
.tdCustomer {
  width: 5%;
}
.tdProductName {
  width: 12%;
}
.tdProductPrice {
  width: 10%;
}
.tdQuantity {
  width: 3%;
}
.tdDiscountItem {
  width: 5%;
}
.tdTotalAmount {
  width: 10%;
}
.tdTotalPayment {
  width: 10%;
}
.tdNote {
  width: 10%;
}
.tdReturnFee {
  width: 10%;
}
.tdAction {
  width: 5%;
}
.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}
.search-col {
  display: flex;
  align-items: center;
}
.first-search-col {
  display: flex;
  align-items: center;
}
.customer-input {
  border-style: solid;
  border-width: 1px;
  border-color: thistle;
  border-radius: 5px;
}
.tdCenter {
  text-align: center !important;
  vertical-align: middle;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
